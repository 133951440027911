
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$simulation-angular-app-primary: mat.define-palette(mat.$indigo-palette);
$simulation-angular-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$simulation-angular-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$simulation-angular-app-theme: mat.define-light-theme((
  color: (
    primary: $simulation-angular-app-primary,
    accent: $simulation-angular-app-accent,
    warn: $simulation-angular-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($simulation-angular-app-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-primary.mat-flat-button {
  background-color: #1d2036 !important;
}

label {
  color: #9a9a9a;
  font-size: 16px;
}

.location-modal {
  .mat-dialog-container {
    background-color: #262b46;
    padding: 10px;
  }
}
.share-modal {
  .mat-dialog-container {
    background-color: #1d2036;
  }
}

.capex-opex-wrapper {
  .mat-slider {
    padding: 0;
  }
  .mat-slider.mat-slider-horizontal {
    width: 300px;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-wrapper {
    top: 0 !important;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
    height: 20px;
    border-radius: 10px;
    background-color: #f6f3f1 !important;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-background,
  .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    height: 100%;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-background {
    transform: none !important;
    background-color: #f6f3f1 !important;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    background-color: #7ab0f9 !important;
  }
  .mat-accent .mat-slider-thumb {
    display: none;
  }
  .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    background-color: white;
  }
}

.dashboard-wrapper {
  .mat-slider {
    margin-left: 10px;

    .mat-slider-track-wrapper {
      height: 16px !important;
      top: -7px;
      border-radius: 8px;
      background-color: #1d2036;
      .mat-slider-track-background {
        height: 16px;
        background-color: #1d2036;
      }
      .mat-slider-track-fill {
        height: 16px;
        background-color: #1d2036;
      }
    }
    .mat-slider-thumb-container {
      .mat-slider-thumb {
        background-color: #6669f6;
      }
    }
  }
}

.operations-sim-wrapper {
  .cons-area-slider {
    width: 371px;
    margin-top: 30px;
  }
  .mat-accent .mat-slider-track-fill, .mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label {
    background-color: #FFFFFF;
    border: 1px solid #F08035;
  }
  mat-slider.mat-slider-horizontal .mat-slider-wrapper {
    top: 0 !important;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
    height: 6px;
    border-radius: 3px;
    background-color: #f6f3f1 !important;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-background,
  .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    height: 100%;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-background {
    transform: none !important;
    background-color: #E2E8F0 !important;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    background-color: #F08035 !important;
  }
  .mat-accent .mat-slider-thumb {
    bottom: -12px;
  }
  .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    background-color: white;
  }
}

.authentication-wrapper, .onboarding-wrapper {
  .lang-field {
    width: 125px;

    .mat-form-field-outline {
      height: 39px;
    }

    .mat-form-field-infix {
      padding: 0.4em 0 0.5em 0;
      border-top: 0.84375em solid transparent;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-select-value {
      text-align: center;
    }

    .mat-select-value-text {
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #23384D;
    }

    .mat-select-arrow-wrapper {
      transform: unset;
    }

    .mat-select-arrow {
      width: 9px;
      height: 9px;
      border: none;
      background: url("assets/chevron-down.png") center no-repeat;
    }
  }

  .email-field, .onboarding-field {
    .mat-form-field-outline-start {
      border-radius: 6px 0 0 6px;
    }

    .mat-form-field-outline-end {
      border-radius: 0 6px 6px 0;
    }

    .mat-form-field-outline {
      border-radius: 6px;
      background: #FFFFFF;
    }

    input {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: #23384D;

      &::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #D7D7D7;
      }
    }
  }
}

.lang-select {
  .mat-option {
    height: 48px !important;
    background: #F1F4F9;
    width: 100%;

    &.mat-selected {
      background: #F1F4F9;
    }

    .mat-option-text {
      font-size: 16px;
    }
  }
}

.signup-checkbox {
  label {
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.005em;
    text-decoration-line: underline;
    color: #535353;

    .mat-checkbox-frame {
      border: 1px solid #23384D !important;
    }

    .mat-checkbox-checkmark-path {
      stroke: #FFFFFF !important;
    }
  }

  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: #23384D !important;
    }
  }
}

.sidenav {
  .mat-drawer-inner-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .tree-control {
    background: #22374D;
    margin-left: 54px;
    margin-right: 6px;
    max-height: 450px;
    overflow-x: clip;
    overflow-y: auto;
    padding-left: 6px;

    .mat-tree-node {
      position: relative;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.011em;

      &.normal-font {
        font-weight: 300;
      }

      &.available-node {
        color: #FFB46E;
      }

      &:first-child {
        .tree-line-level-0 {
          height: 50%;
          top: 50%;
        }
      }

      &:last-child {
        .tree-line-level-0 {
          height: 50%;
          bottom: 50%;
        }
      }
    }

    .tree-line-level-0 {
      position: absolute;
      height: 100%;
      border-right: 1px solid #FFFFFF;
      left: 0;
    }

    .tree-line-level-1 {
      position: absolute;
      height: 100%;
      border-right: 0.75px dashed #FFFFFF;
      left: 40px;
    }

    .circles-container {
      display: flex;
      background: #22374D;
      margin-left: -7px;
      height: 15px;
      z-index: 2;

      .icon-level-1 {
        font-size: 15px;
        height: 15px;
        width: 15px;
      }
    }

    .item-circle-out {
      width: 7px;
      height: 7px;
      background: #22374D;
      border: 1px solid #FFFFFF;
      border-radius: 50%;
      position: relative;
      margin-left: -4px;

      &.level-1 {
        background: #FFFFFF;
      }

      &.available {
        background: #FFFFFF;
        width: 13px;
        height: 13px;
        margin-left: -6px;
      }

      .item-circle-in {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #FFB46E;
        position: absolute;
        top: 3px;
        left: 3px;
      }
    }

    .mat-icon-button {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      border-radius: 0;
      width: auto;
      margin-left: 15px;
    }

    .node-name {
      margin-left: 10px;

      &.low-level {
        margin-left: 24px;
      }

      &.clickable-node {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .tree-control::-webkit-scrollbar {
    width: 6px;
  }

  .tree-control::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  .tree-control::-webkit-scrollbar-thumb {
    background-color: #d4aa70;
    border-radius: 100px;
  }
}

.logo-menu {
  background: #22374D;

  .mat-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
    width: 267px;
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    height: 46px;
    background: #22374D;

    &.company-name {
      pointer-events: none;
      background: #4e5f71;
    }

    &.logout-item {
      color: #FFB46E;
    }

    &:hover {
      background: #7d838a33;
    }

    .logo-button {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      color: #FFFFFF;
      background: #22374D;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: auto 20px auto 4px;
      cursor: pointer;

      .logo-button-title {
        width: 18.2px;
        height: 5.28px;
      }
    }

    .settings-icon, .logout-icon {
      margin: auto 22px auto 9px;
    }
  }

  .block-divider {
    border-top: 1px solid #D8D8D8;;
    position: relative !important;
    margin: 0 20px;
  }
}

.what-next-dialog {
  width: 532px;
  height: 336px;
  background: #FFFFFF;
  box-shadow: 0 6px 10px -1px rgba(6, 25, 56, 0.07);
  border-radius: 8px;
}

.invite-dialog {
  width: 872px;
  height: 390px;
  background: #FFFFFF;
  border-radius: 10px;

  .mat-form-field.email-field {
    width: 260px;
    margin-right: 36px;

    .mat-input-element, .mat-input-element::placeholder {
      font-size: 14px;
      color: #728095;
    }
  }

  .mat-form-field.join-mode-field {
    width: 130px;
  }

  .mat-dialog-container {
    border-radius: 10px;
    padding: 25px 35px;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: #CBD4E1;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick,
  .mat-form-field-appearance-outline .mat-form-field-outline {
    height: 56px;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-outline-thick .mat-form-field-outline-start {
    border-radius: 8px 0 0 8px;
    border-color: #CBD4E1;
    border-right-style: none;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end,
  .mat-form-field-outline-thick .mat-form-field-outline-end {
    border-radius: 0 8px 8px 0;
    border-color: #CBD4E1;
    border-left-style: none;
  }

  .join-mode-field {
    margin-top: 2px;

    .mat-select-value-text {
      font-size: 16px;
      color: #728095;
    }

    .mat-select-arrow-wrapper {
      transform: unset;
    }

    .mat-select-arrow {
      width: 15px;
      height: 15px;
      border: none;
      background: url("assets/chevron-down.png") center no-repeat;
    }
  }
}

.invite-dialog-select {
  width: 304px;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4) !important;
  border-radius: 12px !important;
  padding: 8px 4px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .mat-option {
    height: 48px !important;
    background: #FFFFFF;
    border-radius: 8px;
    width: 100%;

    &.mat-selected {
      background: #F1F4F9;

      .mat-option-text {
        color: #F08035;
      }
    }

    .mat-option-text {
      font-size: 16px;
      color: #999CA0;
    }
  }
}

.project-mapping-wrapper {
  .cons-area-slider {
    width: 290px;
    margin: 30px 0 0 20px;
  }
  .mat-accent .mat-slider-track-fill, .mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label {
    background-color: #F08035;
  }
  mat-slider.mat-slider-horizontal .mat-slider-wrapper {
    top: 0 !important;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
    height: 6px;
    border-radius: 3px;
    background-color: #f6f3f1 !important;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-background,
  .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    height: 100%;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-background {
    transform: none !important;
    background-color: #E2E8F0 !important;
  }
  .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    background-color: #F08035 !important;
  }
  .mat-accent .mat-slider-thumb {
    bottom: -12px;
  }
  .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    background-color: white;
  }

  .map-with-marker {
    .gm-style {
      div:nth-child(2) {
        div:first-child {
          div:nth-child(4) {
            div:nth-child(2) {
              margin-top: 17px;
            }
          }
        }
      }
    }
  }
}

.consumers-wrapper {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.55em 0 0.63em 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0;
  }

  .mat-checkbox-frame {
    border: 1px solid #CBD4E1;
    border-radius: 4px;
  }

  .mat-checkbox-inner-container {
    width: 18px;
    height: 18px;
  }

  .mat-checkbox-checked.mat-accent {
    .mat-checkbox-frame {
      border-radius: 4px;
    }

    .mat-checkbox-background {
      background-color: #F08035;
    }
  }

  .checked input {
    font-weight: 600;
    color: #F08035;
  }
}

.load-demand-review-wrapper {
  .mat-button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
  }
}

.completed-dialog {
  width: 600px;
  background: #FFFFFF;
  box-shadow: 0 6px 10px -1px rgba(6, 25, 56, 0.07);
  border-radius: 8px;
}

.load-demand-review-dialog {
  .mat-dialog-container {
    background: #FFFFFF;
    box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
}

.custom-consumption-dialog {
  input {
    font-weight: 600;
    color: #F08035;
  }

  .amount-field {
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 0.55em 0 0.63em 0;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-end {
      border-radius: 0;
    }
  }

  .mat-form-field-appearance-outline.name-field {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      .label-field {
        margin-top: -13px;
        font-weight: 500;
        font-size: 14px;
        color: #CDCDCD;
      }
    }
  }

  .mat-dialog-container {
    border-radius: 10px;
    padding: 60px 35px 35px 45px;
  }
}

.edit-assumptions-dialog {
  input {
    font-weight: 500;
    color: #535353;
    text-align: center;
  }

  .mat-form-field-appearance-outline.mat-focused {
    .inc-button, .dec-button {
      visibility: visible !important;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.55em 0 0.63em 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 2px 2px 0;
    border: 1px solid #F7F7F7;
    border-left-style: none;
    background: #F7F7F7;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-end {
    border-radius: 0 2px 2px 0;
    border: 1px solid #69B2FF;
    border-left-style: none;
    background: #FFFFFF;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 2px 0 0 2px;
    border: 1px solid #F7F7F7;
    border-right-style: none;
    background: #F7F7F7;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-start {
    border-radius: 2px 0 0 2px;
    border: 1px solid #69B2FF;
    border-right-style: none;
    background: #FFFFFF;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-dialog-container {
    border-radius: 10px;
    padding-left: 53px;
  }
}

.account-settings-wrapper {
  .mat-tab-header {
    width: 550px;
    border-bottom: 2px solid #94A3B8;
  }

  .mat-tab-labels {
    justify-content: space-between;
  }

  .mat-tab-label {
    font-weight: normal;
    font-size: 24px;
    line-height: 20px;
    color: #27364B;
    opacity: 1;

    &.mat-tab-label-active {
      font-weight: bold;
      font-size: 24px;
      line-height: 20px;
      text-align: center;
      color: #F08035;
    }
  }

  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #F08035;
  }

  .account-container {
    .account-field {
      width: 100%;

      .mat-form-field-infix {
        padding: 0.5em 0;
        border-top: 0;
      }

      .mat-form-field-outline-start {
        border-radius: 6px 0 0 6px;
      }

      .mat-form-field-outline-end {
        border-radius: 0 6px 6px 0;
      }

      .mat-form-field-outline {
        border-radius: 6px;
        background: #FFFFFF;
      }

      input {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #23384D;

        &::placeholder {
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #D7D7D7;
        }
      }
    }
  }

  .language-slider-container {
    .mat-slide-toggle .mat-slide-toggle-bar {
      background-color: rgba(255, 180, 110, 0.3);
    }

    .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
      background-color: #FFB46E;
    }

    .mat-slide-toggle .mat-slide-toggle-thumb {
      background-color: #FFB46E;
      margin-top: 6px;
    }
  }

  .mat-slide-toggle .mat-slide-toggle-bar {
    background-color: #EBEBEB;
  }

  .mat-slide-toggle .mat-slide-toggle-thumb-container {
    transform: translate3d(3px, 0, 0);
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(29px, 0, 0);
  }

  .mat-slide-toggle-bar {
    width: 56px;
    height: 32px;
    border-radius: 16px;
  }

  .mat-slide-toggle-thumb {
    height: 24px;
    width: 24px;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(255, 180, 110, 0.3);
  }

  .mat-slide-toggle-thumb {
    margin-top: 6px;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #FFB46E;
    margin-top: 6px;
  }

  .mat-slide-toggle .mat-slide-toggle-ripple {
    top: calc(50% - 13px);
    left: calc(50% - 20px);
  }

  .mat-slide-toggle.cdk-focused.mat-checked .mat-ripple-element {
    background-color: #FFB46E;
  }
}

.logo-uploader-dialog {
  width: 535px;
  background: #FFFFFF;
  box-shadow: 0 6px 10px -1px rgba(6, 25, 56, 0.07);
  border-radius: 8px;
}

.contact-dialog {
  width: 872px;
  height: 311px;
  background: #FFFFFF;
  border-radius: 10px;

  .mat-dialog-container {
    border-radius: 10px;
    padding: 25px 35px;
  }
}

.capital-raising-wrapper {

  .done-icon-container {
    width: 32px;
    height: 32px;
    background: #FF6D09;
    border-radius: 100px;
    border: 4px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
  }

  .number-icon-container {
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 100px;
    border: 4px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #FF6D09;
  }

  .mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected {
    .number-icon-container {
      background: #FF6D09;
      color: #FFFFFF;
    }
  }

  .mat-step-icon.mat-step-icon-state-done, .mat-step-icon.mat-step-icon-state-edit, .mat-step-icon.mat-step-icon-state-number {
    width: 44px;
    height: 44px;
    background: #FF6D09;
    border-radius: 100px;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
    padding: 14px 24px;
  }

  .mat-step-text-label {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #B3B3B3;
  }

  .mat-stepper-horizontal-line {
    border-top-width: 2px;
    border-top-style: solid;
    border-top-color: #FF6D09;
    flex: auto;
    height: 0;
    margin: 0 -16px;
    min-width: 32px;
  }

  .mat-horizontal-stepper-header-container {
    width: 770px;
    margin: 0 auto;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
  [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before,
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
  [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
    border-top-width: 2px;
    border-top-style: solid;
    border-top-color: #FF6D09;
  }

  .mat-radio-container {
    width: 14px;
    height: 14px;

    .mat-radio-inner-circle {
      height: 8px;
      width: 8px;
      left: 3px;
      top: 3px;
    }

    .mat-radio-outer-circle {
      height: 14px;
      width: 14px;
      border: 1px solid #D5D8DE;
    }
  }

  .mat-checkbox-checked {
    &.mat-accent .mat-checkbox-background {
      background-color: #69B2FF !important;
    }
    .mat-radio-inner-circle {
      background-color: #FFFFFF;
      height: 8px;
      width: 8px;
      left: 3px;
      top: 3px;
    }

    .mat-radio-outer-circle {
      border-color: #69B2FF !important;
      background-color: #69B2FF !important;
      height: 14px;
      width: 14px;
    }
  }
}

@media (max-height: 900px) {
  .capital-raising-wrapper {
    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
      top: 28px;
    }

    .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
      top: 28px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .capital-raising-wrapper .mat-vertical-stepper-header {
      padding: 5px 24px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .capital-raising-wrapper .mat-vertical-stepper-header {
      padding: 5px 24px;
    }

    .mat-step-text-label {
      font-size: 14px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
      padding: 0;
    }
  }
}

@media (max-width: 1000px) {
  .capital-raising-wrapper {
    form {
      width: 100%;
    }

    form .textarea-form-field .mat-form-field-wrapper {
      width: 430px;
    }

    form .field-row .mat-form-field {
      width: unset;
    }

    .mat-horizontal-stepper-header-container {
      width: 630px;
    }

    .mat-step-text-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      color: #B3B3B3;
    }

    form.anchor-form .textarea-form-field .mat-form-field-wrapper {
      width: 421px;
    }

    form.anchor-form .field-row .field-label {
      width: 150px;
    }

    form.anchor-form {
      align-items: flex-start;
    }
  }
}

.battery-switch-container {
  .mat-slide-toggle.mat-disabled {
    opacity: 1 !important;
    margin: 5px;
  }

  .mat-slide-toggle .mat-slide-toggle-bar {
    background-color: #EBEBEB;
  }

  .mat-slide-toggle .mat-slide-toggle-thumb-container {
    transform: translate3d(3px, 0, 0);
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(29px, 0, 0);
  }

  .mat-slide-toggle-bar {
    width: 56px;
    height: 32px;
    border-radius: 16px;
  }

  .mat-slide-toggle-thumb {
    height: 24px;
    width: 24px;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(255, 180, 110, 0.3);
  }

  .mat-slide-toggle-thumb {
    margin-top: 6px;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #FFB46E;
    margin-top: 6px;
  }

  .mat-slide-toggle .mat-slide-toggle-ripple {
    top: calc(50% - 13px);
    left: calc(50% - 20px);
  }

  .mat-slide-toggle.cdk-focused.mat-checked .mat-ripple-element {
    background-color: #FFB46E;
  }
}

.financials-review-dialog {
  .mat-dialog-container {
    background: #FFFFFF;
    box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
}

.simulation-review-dialog {
  .mat-dialog-container {
    background: #FFFFFF;
    box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
}

.mat-snack-bar-container {
  background-color: #F08035;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
}
